<template>
  <div>
    <section
      v-if="isSubscribedBrimePro"
      class="info"
    >
      <div class="row">
        <div class="column"><img
          src="https://cdn.discordapp.com/attachments/804883514603470890/827099538882822154/FeelsBrimeProMan.png"
          width="100px"
          title="feelsBrimeProMan"
          alt="feelsBrimeProMan"
        ></div>
        <div class="column2">
          <span class="brimeProTitle">Thank you for being a BrimePro subscriber.<br> Brime Doge also says thanks for putting food in his bowl.</span>
        </div>
      </div>

      <br><br>
      <b-col
        md="4"
        xl="4"
        class="mb-1"
      >
        <b-form-group
          label="Custom Username Color"
          label-for="color"
        >
          <b-form-input
            id="color"
            v-model="userColor"
            class="colorSelector"
            type="color"
            @change="updateUserColor()"
          /><br>
          <b-form-checkbox
            checked="true"
            name="check-button"
            switch
            inline
          >
            Enable additional VOD Storage (30 days)
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <br><br><br><hr class="cancelHr"><b-button
        id="subscribe"
        variant="primary"
        type="submit"
        size="sm"
        @click="cancel"
      >
        Cancel Subscription
      </b-button>
    </section>
    <section
      v-if="error"
      class="errors"
    >
      The following fields appear to be invalid: {{ error.fields.join(', ') }}
    </section>
    <section v-if="!isSubscribedBrimePro">
      <h4>$6.99 / mo.</h4><br>
      BrimePro™️ - Alpha Features
      - No ADS anywhere<br>
      - Set Chat Username Color<br>
      - Enable 30 Days of VODs<br>
      - BrimePro™️ Badge<br>
      -1 monthly non-renewing tier-1 sub to the creator of your choice.<br><br>(Refresh page after you've subscribed)<br>
      <br>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        size="sm"
        variant="primary"
        style="line-height:14px;"
        class="float-left"
        @click="openCheckout()"
      >Subscribe
      </b-button>
    </section>
    <!-- <b-modal
      id="modal-center"
      centered
      :title="'Subscribe to '"
      ok-only
      ok-title="Subscribe"
      size="lg"
    >
      <b-card-text>
        By clicking "Subscribe", you agree to Brime's
        <a
          href="https://support.brimelive.com/hc/en-us/articles/360054884471-Terms-of-Service"
          target="_blank"
          >Terms of Sale</a
        >
        and acknowledge our
        <a
          href="https://support.brimelive.com/hc/en-us/articles/360054441412-Privacy-Policy"
          target="_blank"
          >Privacy Policy</a
        >
        applies. Your payment method will be saved for future purchases, and, if
        applicable, recurring subscription payments.
      </b-card-text>
    </b-modal> -->
  </div>
</template>
<script>
import {
  BFormInput, BFormGroup, BButton, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post.Authorization = `Bearer ${window.brime.getAccessToken()}`
export default {
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BButton,
    BFormGroup,
    BCol,
    BFormCheckbox,
  },
  data() {
    return {
      userColor: '',
      firstName: '',
      lastName: '',
      error: null,
      isSubscribedBrimePro: '',
      options: {},
    }
  },
  async beforeCreate() {
    this.userData = await window.brime.getUser()
    if (!this.userData) {
      this.$router.push('/login')
    }
    if (await this.userData.creatorStatus === 'ELITE') {
      this.monetized = true
    }
    this.userColor = this.userData.color
    try {
      this.isSubscribedBrimePro = this.userData.isBrimePro
    // eslint-disable-next-line no-empty
    } catch (ignored) {}
  },
  async mounted() {
    this.cbInstance = window.Chargebee.init({ site: 'brime' })
    document.title = 'BrimePro - Brime Live'
    // Set this to your public key
    // On form submit, we stop submission to go get the token

    // A simple error handling function to expose errors to the customer
  },
  methods: {
    openCheckout() {
      this.cbInstance.openCheckout({
        hostedPage: () => {
          const messageBody = {
            plan_id: 'brime-pro',
            channel_id: '60aa6caa0cfc1c99b4d7c2b7',
            channel: 'brime-pro',
          }
          return axios.post(`${window.brime.apiBase}/internal/billing/generate_checkout_new_url?client_id=${window.brime.clientId}`, messageBody).then(response => response.data.data.hosted_page)
        },
      })
    },
    async updateUserColor() {
      window.brime.updateUserInformation(this.userColor)
      const colorSuccess = 'Username color updated'
      this.$bvToast.toast(colorSuccess, {
        title: 'User Color',
        variant: 'success',
        solid: true,
      })
    },
    async cancel() {
      await window.brime.cancelBrimeProSubscription()
      const message = 'Subscription Canceled'
      this.$bvToast.toast(message, {
        title: 'Cancellation',
        variant: 'success',
        solid: true,
      })

      setTimeout(() => { window.location.reload() }, 3000)
    },
    submit() {
    },
  },
}
</script>
<style scoped>
.cancelHr{
max-width: 500px;
margin-left: 0 !important;
padding-bottom: 20px;
}
.brimeProTitle{
font-size: 15px;
height: auto;
line-height: normal;
max-height: none;
min-height: 0px;
}
.colorSelector{
max-width: 80px;
}
.pp{
max-width: 200px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #fff;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
.subscribe{
max-width: 750px;
}
form{
max-width: 500px;
}
.column{
margin-left: 20px;
}
.column2{
padding-top: 30px;
font-size: 15px;
margin-left: 20px;
}
</style>
